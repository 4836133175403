/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { BASE_URL } from '../app/constants';
import { handleErrorWithCode } from './responseHandler';
import { loginAxiosConfig as axiosConfig } from './common';

export async function signUpUser(data) {
  const url = `${BASE_URL}/api/v2/auth/signup`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function loginUser(data) {
  const url = `${BASE_URL}/api/v2/auth/login`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function sendOTPForSignUp(data) {
  const url = `${BASE_URL}/api/v2/auth/send-otp-signup`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function sendOTPForForgotPassword(data) {
  const url = `${BASE_URL}/api/v2/auth/send-otp-forgot-password`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function verifyOTP(data) {
  const url = `${BASE_URL}/api/v2/auth/verify-otp`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateForgotPassword(data) {
  const url = `${BASE_URL}/api/v2/auth/update-forgot-password`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function isAlreadyExistEmailOrMobile(data) {
  const url = `${BASE_URL}/api/v2/auth/check-active`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
} 

export async function sendOTPForEmailForgotPassword(data) {
  const url = `${BASE_URL}/api/v2/auth/send-otp-email-forgot-password`;

  try {
    const result = await axios.post(url, data, axiosConfig);
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}