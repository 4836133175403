import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import {
  Formik, Form,
} from 'formik';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import RegisterImage from '../../images/loginicon/Register_Image.png';
import Indiaflagexp from '../../images/loginicon/India-flag.jpg';
import CA_Blue from '../../images/paln_CA_img_blue.png';
import SME_Blue from '../../images/user_end_sme_blue.png';
import CA_White from '../../images/paln_CA_img_white.png';
import SME_White from '../../images/user_end_sme_white.png';
import NumericInput from '../../components/NumericInput';
import ShowAlert from '../../components/ShowAlert';
import { sendOTPForSignUp, signUpUser, isAlreadyExistEmailOrMobile } from '../../api/authenticationAPI';
import ErrorFocus from '../../components/ErrorFocus';
import Verify from './Verify';
import { BASE_PATH } from '../../app/constants';
import TermsAndConditionsPopup from './TermsAndConditionsPopup';
import './LoginScreen.scss';
import { salesiq_to_crm, initializeSalesIq } from './visitor_info.js';
import { gtmConversionSignup, gtmConversionSignup_btn } from './gtm.js';
import exclamationIcon from '../../images/exclamation.png';
import { encryptPasswordString } from '../../utils/UtilityFunctions';
import { updateAuthToken } from '../../utils/AuthToken';

const WhatsAppMobileMessage = "OTP will be sent on this whatsapp number for Verification"

const atLeasetDigit = new RegExp(/(?=.*\d)[\S]{1,}$/);
const atLeasetUpperCase = new RegExp(/(?=.*[A-Z])[\S]{1,}$/);
const atLeasetLowerCase = new RegExp(/(?=.*[a-z])[\S]{1,}$/);
// const atLeasetSpecial = new RegExp(/(?=.*[@$!%*?&])[\S]{1,}$/);
const atLeasetEight = new RegExp(/[\S]{8,}$/);

export const signUpFormValidationSchema = Yup.object().shape({
  mobile_no: Yup.number()
    .typeError('This field is required')
    .required('Please enter Valid Mobile Number')
    .min(5999999999, 'Invalid Mobile Number')
    .max(9999999999, 'Invalid Mobile Number'),
  email: Yup.string().trim().required('This field is required').email('Invalid Email'),
  full_name: Yup.string()
    .required('This field is required')
    .max(75, 'Maximum 75 characters allowed')
    .matches(/[A-Za-z0-9 .&_/-]+$/, 'Invalid Name'),
  organization_name: Yup.string()
    .required('This field is required')
    .max(75, 'Maximum 75 characters allowed')
    .matches(/[A-Za-z0-9 .&_/-]+$/, 'Invalid Organization / Firm Name'),
  password: Yup.string().trim()
    .required('This field is required')
    .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/, 'Invalid Password'),
  password_confirmation: Yup.string()
    .required('This field is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  organization_type: Yup.string()
    .required('This field is required'),
  pincode: Yup.number()
    .required('This field is required')
    .min(100000, 'Valid Indian Pin (Postal) code is required.')
    .max(999999, 'Valid Indian Pin (Postal) code is required.'),
});

const SignUpScreen = ({ history }) => {
  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);
  initializeSalesIq();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTooltip, setShowPasswordTooltip] = useState(false);
  const [signUpData, setSignUpData] = useState({
    otp_verification_id: '',
    country_code: '+91',
    mobile_no: null,
    email: '',
    full_name: '',
    organization_name: '',
    password: '',
    password_confirmation: '',
    pincode: '',
    utm_source: 'expitrdirect',
    organization_type: '',
    terms_and_conditions: true,
  });

  const [showTandC, setShowTandC] = useState(false);
  const [mobileExist, setMobileExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);

  const sendSignUpOTP = async (values) => {
    setLoading(true);
    gtmConversionSignup_btn();
    values["mobile_no"] = parseInt(values["mobile_no"]);
    salesiq_to_crm(values.full_name, values.email, values.mobile_no, values.organization_type, values.pincode, values.utm_source);

    //password encryption
    //values['password'] = encryptPasswordString(values['password']);
    //values['password_confirmation'] = encryptPasswordString(values['password_confirmation']);

    setSignUpData({ ...signUpData, ...values });
    const data = {
      country_code: values.country_code,
      mobile_no: values.mobile_no,
      email: values.email,
      organization_type: values.organization_type,
      organization_name: values.organization_name,
      pincode: values.pincode,
    };

    try {
      const result = await sendOTPForSignUp(data);
      setIsOTPSent(true);
      setSignUpData({ ...values, otp_verification_id: result.data.otp_verification_id });
      setSuccess(result?.message);
    } catch (err) {
      setError(err.message);
      console.error('Error in send otp: ', err);
    }
    setLoading(false);
  };

  const signUp = async (data) => {
    setLoading(true);

     //encrypt password strings
     data['password'] = encryptPasswordString(data['password']);
     data['password_confirmation'] = encryptPasswordString(data['password_confirmation']);
     
    try {
      const result = await signUpUser(data);
      setSuccess(result.message);
      updateAuthToken(result.data.token);
      localStorage.setItem('signup', true);
      gtmConversionSignup();
      history.push(`${BASE_PATH}client/recent`);
    } catch (err) {
      setError(err.message);
      console.error('Error in send otp: ', err);
    }
    setLoading(false);
  };

  useEffect(() => {
    const newParams = {};
    Object.keys(params).forEach((paramKey) => {
      if (Array.isArray(params[paramKey])) {
        newParams[paramKey] = params[paramKey][0];
      } else {
        newParams[paramKey] = params[paramKey];
      }
    });
    setSignUpData({ ...signUpData, ...newParams });
    //eslint-disable-next-line
  }, [searchStr]);


  const checkAlreadyExistMobileOrEmail = async (type, value, setFieldError) => {
    try {
      const payload = {
        country_code: '+91',
        mobile_no: type === 'mobile_no' ? value : '',
        email: type === 'email' ? value : '',
      }
      const result = await isAlreadyExistEmailOrMobile(payload);
      if (type === 'mobile_no') {
        setMobileExist(false);
        if (Number(result?.data ?? 0) === 1) {
          setFieldError(type, 'Already registered number');
          setMobileExist(true);
        }
      } else if (type === 'email') {
        setEmailExist(false);
        if (Number(result?.data ?? 0) === 1) {
          setFieldError(type, 'Already registered email id');
          setEmailExist(true);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleValdationOnBlur = (e, setFieldError, values) => {

    const { name, value } = e.target;

    if (value.trim().length === 0) {
      setFieldError(name, 'This field is required');
    } else {

      if (name === 'mobile_no' && (Number(value) < 5999999999 || Number(value) > 9999999999)) {
        setFieldError('mobile_no', 'Invalid Mobile Number');
      } else if (name === 'mobile_no' && (Number(value) > 5999999999 && Number(value) < 9999999999)) {
        checkAlreadyExistMobileOrEmail('mobile_no', value, setFieldError);
      }


      if (name === 'email') {
        var mailformat = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!mailformat.test(value)) {
          setFieldError('email', 'Invalid Email');
        } else {
          checkAlreadyExistMobileOrEmail('email', value, setFieldError);
        }
      }

      if (name === 'password') {
        var passwordformat = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/
        if (!passwordformat.test(value)) {
          setFieldError('password', 'Invalid Password');
        }
      }

      if (name === 'password_confirmation') {
        const password = values?.password ?? '';
        if (password !== value) {
          setFieldError('password_confirmation', 'Passwords must match');
        }
      }
    }
  }

  return (
    <div className='main-loginscreen'>
      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />
      <TermsAndConditionsPopup show={showTandC} handleClose={() => setShowTandC(false)} />
      {isOTPSent ? (
        <Verify
          mobile_no={signUpData.mobile_no}
          otp_verification_id={signUpData.otp_verification_id}
          handleProceed={(otpVerificationId) => {
            setSignUpData({ ...signUpData, otp_verification_id: otpVerificationId });
            signUp({ ...signUpData, otp_verification_id: otpVerificationId });
          }}
          resendOTP={() => sendSignUpOTP(signUpData)}
          setIsOTPSent={setIsOTPSent}
          setError={setError}
        />
      ) : (
        <div className="container clear-padd ma-0 position-center ng-scope bgclretds mt-2">

          <div className="col-xs-2 col-sm-2 col-sm-2 login-empty-container" />
          <div className="col-xs-12 col-lg-12 col-md-12 col-sm-12 login-container mt-28 vertical-center">

            <div className="row">
              <div className=" col-md-6 mt-2">
                <p className="">
                  <span className="fileetdssm" style={{ color: ' #31719b' }}>
                    Sign Up for Free Trial
                  </span>

                </p>
              </div>
              <div className=" col-md-6 mt-2 pr-0">
                <span className="btn btn-default float-right backhbtnls">
                  <a href="https://www.expressitr.com/">  Back to Home</a>
                </span>
              </div>

            </div>

            <div className="row">
              <div className="col-xs-12 col-sm-12  col-xs-4 col-sm-4 col-md-4 login-img-caontainer">
                <img className="" src={RegisterImage} alt="ExpressITR Sign Up!" ng-click="close()" />
              </div>

              <div className=" login-form-container col-xs-12 col-sm-12 col-xs-8 col-sm-8 col-md-8 bg-whitecolor formBorder">
                <Formik
                  initialValues={signUpData}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={signUpFormValidationSchema}
                  onSubmit={(values) => {
                    // same shape as initial values
                    sendSignUpOTP(values);
                  }}
                  validate={values => {
                    const errors = {};
                    if (mobileExist) {
                      errors.mobile_no = 'Already registered number'
                    }
                    if (emailExist) {
                      errors.email = 'Already registered email id'
                    }
                    return errors;
                  }}
                  enableReinitialize
                >
                  {({
                    errors, values, setFieldValue, setFieldError
                  }) => {
                    if (Object.keys(errors).length > 0) {
                    }

                    return (
                      <Form key={1} autoComplete="off" className="form-horizontal logforms">
                        <ErrorFocus />

                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="text"
                                  className={`form-control lg-input inputplace inputplace1 ${errors.full_name && errors.full_name !== '' ? 'input-error1' : ''}`}
                                  name="full_name"
                                  placeholder="Enter Full Name*"
                                  required=""
                                  value={values.full_name}
                                  maxLength={75}
                                  onChange={(e) => {
                                    setFieldValue('full_name', e.target.value);
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('full_name', '');
                                    }
                                  }}
                                  disabled={signUpData.okey}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                />
                                {errors.full_name ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.full_name}</span> : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="text"
                                  className={`form-control lg-input inputplace inputplace1 ${errors.organization_name && errors.organization_name !== '' ? 'input-error1' : ''}`}
                                  name="organization_name"
                                  placeholder="Enter Organization / Firm Name*"
                                  required=""
                                  value={values.organization_name}
                                  maxLength={75}
                                  onChange={(e) => {
                                    setFieldValue('organization_name', e.target.value);
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('organization_name', '');
                                    }
                                  }}
                                  disabled={signUpData.okey}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                />
                                {errors.organization_name ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.organization_name}</span> : null}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <div className="input-group">
                                  <div className={`input-group-prepend inpgpbx ${errors.mobile_no && errors.mobile_no !== '' ? 'input-error1' : ''}`}>
                                    <img className="flag_icon" src={Indiaflagexp} alt="mobile" />
                                  </div>
                                  <NumericInput
                                    type="tel"
                                    className={`form-control lg-input inputplace inputplace1 input-auto-size ${errors.mobile_no && errors.mobile_no !== '' ? 'input-error1' : ''}`}
                                    name="mobile_no"
                                    placeholder="Enter WhatsApp No*"
                                    maxLength={10}
                                    value={values.mobile_no}
                                    onChange={(e) => {
                                      setFieldValue('mobile_no', e.target.value);
                                      if (e.target.value.trim().length > 0) {
                                        setFieldError('mobile_no', '');
                                      }
                                    }}
                                    disabled={signUpData.okey}
                                    onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                  />
                                </div>
                                {errors.mobile_no
                                  ? <span className="col-sm-10 signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.mobile_no}</span>
                                  : <span style={{ fontSize: '11px', fontWeight: 'bold', fontStyle: 'italic', color: '#31719b', paddingTop: '5px' }}>
                                    {WhatsAppMobileMessage}
                                  </span>
                                }
                              </div>
                            </div>
                          </div>

                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="email"
                                  className={`form-control lg-input inputplace inputplace1 ${errors.email && errors.email !== '' ? 'input-error1' : ''}`}
                                  name="email"
                                  placeholder="Enter Email ID*"
                                  required=""
                                  value={values.email}
                                  onChange={(e) => {
                                    setFieldValue('email', e.target.value);
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('email', '');
                                    }
                                  }}
                                  disabled={signUpData.okey}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                />
                                {errors.email ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.email}</span> : null}
                              </div>

                            </div>
                          </div>
                        </div>

                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                {showPassword ? (
                                  <div className="input-group tooltipshowoh">
                                    <input
                                      type="input"
                                      className={`form-control lg-input inputplace inputplace1 input-auto-size ${errors.password && errors.password !== '' ? 'input-error1' : ''}`}
                                      name="password"
                                      placeholder="Enter Password*"
                                      required=""
                                      onFocus={() => setShowPasswordTooltip(true)}
                                      value={values.password}
                                      onChange={(e) => {
                                        setFieldValue('password', e.target.value);
                                        if (e.target.value.trim().length > 0) {
                                          setFieldError('password', '');
                                        }
                                      }}
                                      onBlur={(e) => {
                                        setShowPasswordTooltip(false)
                                        handleValdationOnBlur(e, setFieldError, values)
                                      }}
                                    />
                                    <div className={`input-group-prepend eyeipbox ${errors.password && errors.password !== '' ? 'input-error1' : ''}`} onClick={() => setShowPassword(false)} style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faEyeSlash} />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="input-group tooltipshowoh">
                                    <input
                                      type="password"
                                      className={`form-control lg-input inputplace inputplace1 input-auto-size ${errors.password && errors.password !== '' ? 'input-error1' : ''}`}
                                      name="password"
                                      placeholder="Enter Password*"
                                      required=""
                                      onFocus={() => setShowPasswordTooltip(true)}
                                      value={values.password}
                                      onChange={(e) => {
                                        setFieldValue('password', e.target.value);
                                        if (e.target.value.trim().length > 0) {
                                          setFieldError('password', '');
                                        }
                                      }}
                                      autoComplete="new-password"
                                      onBlur={(e) => {
                                        setShowPasswordTooltip(false)
                                        handleValdationOnBlur(e, setFieldError, values)
                                      }}
                                      maxLength={100}
                                    />
                                    <div className={`input-group-prepend eyeipbox ${errors.password && errors.password !== '' ? 'input-error1' : ''}`} onClick={() => setShowPassword(true)} style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={faEye} />
                                    </div>
                                  </div>
                                )}

                                {showPasswordTooltip && (
                                  <div className="toolbox">
                                    <div className="passlist">
                                      <p className="passmust">Password Must</p>
                                      <span>
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetDigit.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have One Number
                                      </span>
                                      <br />
                                      <span>
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetUpperCase.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have One Uppercase Character
                                      </span>
                                      <br />
                                      <span>
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetLowerCase.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have One Lowercase Character
                                      </span>

                                      <span>
                                        <br />
                                        <span className="passlisticon">
                                          <FontAwesomeIcon
                                            icon={faCircle}
                                            color={atLeasetEight.test(values.password) ? 'green' : ''}
                                          />
                                        </span>
                                        Have 8 Characters minimum
                                      </span>

                                    </div>
                                  </div>
                                )}
                                {errors.password ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.password}</span> : null}
                              </div>
                            </div>

                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2 mb-0">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <div className="input-group">
                                  <input
                                    type="password"
                                    className={`form-control lg-input inputplace inputplace1 ${errors.password_confirmation && errors.password_confirmation !== '' ? 'input-error1' : ''}`}
                                    name="password_confirmation"
                                    placeholder="Enter Confirm Password*"
                                    required=""
                                    value={values.password_confirmation}
                                    onChange={(e) => {
                                      setFieldValue('password_confirmation', e.target.value);
                                      if (e.target.value.trim().length > 0) {
                                        setFieldError('password_confirmation', '');
                                      }
                                    }}
                                    autoComplete="new-password"
                                    onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                  />

                                </div>
                                {errors.password_confirmation ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.password_confirmation}</span> : null}
                              </div>

                            </div>
                          </div>
                        </div>
                        {/* --select catg field add--- */}

                        <div className="row ">
                          <div className="col-xs-6 col-sm-6 col-md-6">
                            <div className="form-group form-group2">
                              <div className="col-xs-12 col-sm-12 col-md-12 signup-form-input-box">
                                <input
                                  type="number"
                                  className={`form-control lg-input pincode ${errors.pincode && errors.pincode !== '' ? 'input-error1' : ''}`}
                                  name="pincode"
                                  id="pincode"
                                  placeholder="Enter Pin Code*"
                                  required=""
                                  value={values.pincode}
                                  minLength={6}
                                  maxLength={6}
                                  onChange={(e) => {
                                    setFieldValue('pincode', e.target.value);
                                    if (e.target.value.trim().length > 0) {
                                      setFieldError('pincode', '');
                                    }
                                  }}
                                  onBlur={(e) => handleValdationOnBlur(e, setFieldError, values)}
                                />
                                {errors.pincode ? <span className="signup_error_msg"><img src={exclamationIcon} alt='exclamation icon' className="excltionicon" />{errors.pincode}</span> : null}
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="row ">
                          <div className="col-xs-12 col-sm-12 col-md-12 ">
                            <div className="form-group form-group2 mt-2 mb-1">
                              <label className="selectcatgfs">Select Organization / Firm Type<span className='red_text'>*</span></label>
                              <div className="row organization_box">
                                <div className="col-xs-6 col-sm-6 col-md-6">

                                  <div className="col-xs-12 col-sm-12 col-md-12 pr-2" onClick={(e) => setFieldValue('organization_type', 'CA')}>
                                    <div className="usersignupradiobox_client">
                                      <input type="radio" className="form-check-input" name="Industry"
                                        checked={values.organization_type === 'CA' ? "checked" : ""}
                                        value="CA&#x2f;CS&#x2f;Tax&#x20;Professionals"
                                        onChange={(e) => setFieldValue('industry_type', e.target.value)}
                                        onFocus={() => setFieldValue('organization_type', 'CA')}
                                      />
                                    </div>
                                    <div className={values.organization_type === 'CA' ? "usertypouterbox active" : "usertypouterbox"}>
                                      <div className="media">
                                        <img src={values.organization_type === 'CA' ? CA_White : CA_Blue} width="22" alt="CA" className="align-self-center mr-2" />
                                        <div className="media-body">
                                          <label className="mb-0">

                                            <span className="catextfs active">
                                              I am a Professional (CA, Accountant, Tax Practitioner, GSTP)
                                              &amp;  Filing Returns for Clients
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-6">
                                  <div className="col-xs-12 col-sm-12 col-md-12 ml-4" onClick={(e) => setFieldValue('organization_type', 'CORPORATE')}>
                                    <div className="usersignupradiobox_bussiness">
                                      <input type="radio" className="form-check-input" name="Industry"
                                        checked={values.organization_type === 'CORPORATE' ? "checked" : ""}
                                        value="Corporate&#x2f; SME"
                                        onChange={(e) => setFieldValue('industry_type', e.target.value)}
                                      />
                                    </div>
                                    <div className={values.organization_type === 'CORPORATE' ? "usertypouterbox active" : "usertypouterbox"}>
                                      <div className="media">
                                        <img src={values.organization_type === 'CORPORATE' ? SME_White : SME_Blue} width="22" alt="CORPORATE" className="align-self-center mr-2" />
                                        <div className="media-body">
                                          <label className="mb-0">
                                            <span className="catextfs active">
                                              I am a Business (SME, Corporate, Enterprise)
                                              &amp; Filing Return for Business / Group Business
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                {errors.organization_type ? <span className="signup_error_msg">{errors.organization_type}</span> : null}
                              </div>
                            </div>
                          </div>
                        </div>


                        {/* --select catg field add--- */}
                        <div className="form-group height-40 mb-3">
                          <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                            <input
                              type="checkbox"
                              name="login"
                              required=""
                              checked={values.terms_and_conditions}
                              onChange={(e) => setFieldValue('terms_and_conditions', !values.terms_and_conditions)}
                            />
                            <span className="pl-1 keeplogin">
                              {' '}
                              I agree to the
                              {' '}
                              <Button
                                variant="link"
                                onClick={() => setShowTandC(true)}
                                style={{
                                  padding: '0px', fontSize: '14px', display: 'contents',
                                  fontWeight: '500'
                                }}
                              >
                                Terms & Conditions
                              </Button>
                            </span>

                          </div>
                        </div>

                        <div className="form-group mb-2">
                          <div className="col-xs-12 col-sm-12 col-md-12 text-center">
                            <Button
                              type="submit"
                              bsPrefix="form-control btn red-btn semi-bold btnFont"
                              name="Sign Up"
                              disabled={!values.terms_and_conditions || loading}
                              style={{ width: '234px' }}
                            >
                              {loading ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  {' '}
                                  Loading...
                                </>
                              ) : 'SIGN UP'}
                            </Button>

                          </div>
                        </div>

                        <div className="col-sm-12 col-xs-8 col-sm-12 col-md-12 col-md-offset-2 text-center padding-25 mtn-37">
                          <p className="ng-binding alsintextsiz">
                            <span className="pr-2">Already have an account?</span>
                            <Link className="sinintext" to={`${BASE_PATH}login`}> Sign In</Link>
                          </p>
                        </div>

                      </Form>
                    );
                  }}
                </Formik>

              </div>

            </div>
          </div>
          <div className="col-sm-2 login-empty-container" />

        </div>
      )}
    </div>

  );
};

export default SignUpScreen;