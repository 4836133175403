/* eslint-disable import/prefer-default-export */
import { getAuthToken } from '../utils/AuthToken';
import axios from 'axios';
import { BASE_URL } from '../app/constants';
import { handleSuccess, handleError } from './responseHandler';

export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken(true)}`,
  }
});


export const getConfigForDeleteHttpVerb = (data) => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAuthToken(true)}`,
  }, data
});
export const getConnectorConfig = { crossdomain: true };

export async function gocallApi(type = '', geturl = '', data = null, needCompleteResult = false) {
  
  if (geturl === '' || type === '') return;
  let url = `${BASE_URL}${geturl}`;
  let result = {}
  try {
    switch (type.toLocaleLowerCase()) {
      case 'get':
        result = await axios.get(url, getConfig());
        break;
      case 'put':
        result = await axios.put(url, data, getConfig());
        break;
      case 'post':
        result = await axios.post(url, data, getConfig());
        break;
      case 'delete':
        result = await axios.delete(url, getConfigForDeleteHttpVerb(data));
        break;
      default:
    }

    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    if (needCompleteResult) {
      return handleSuccess(result);
    } else {
      return handleSuccess(result.data);
    }
  }
  catch (err) {
    throw handleError(err);
  }

}